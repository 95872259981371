// Misc.
	$misc: (
		z-index-base:		10000
	);

// Duration.
	$duration: (
		menu:				0.5s,
		transition:			0.2s,
		slider: 			1.5s
	);

// Size.
	$size: (
		border-radius:		0,
		border-width:		1px,
		element-height:		2.75em,
		element-margin:		2em
	);

// Font.
	$font: (
		family:				('Source Sans Pro', Arial, Helvetica, sans-serif),
		family-fixed:		('Courier New', monospace),
		family-alt: 		('Oswald', 'Source Sans Pro', Arial, Helvetica, sans-serif),
		weight:				400,
		weight-bold:		600
	);

// Palette.
	$palette: (
		bg:					#fff,
		fg:					#888,
		fg-bold:			#666,
		fg-light:			#aaa,
		border:				rgba(144,144,144,0.25),
		border-bg:			rgba(144,144,144,0.075),
		border2:			rgba(144,144,144,0.5),
		border2-bg:			rgba(144,144,144,0.2),
		highlight:			accent1,

		accent1: (
			bg:				#e06279,
			fg:				mix(#e06279, #fff, 10%),
			fg-bold:		#fff,
			fg-light:		mix(#e06279, #fff, 30%),
			border:			rgba(255,255,255,0.25),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(255,255,255,0.5),
			border2-bg:		rgba(255,255,255,0.2),
			highlight:		accent1
		),

		accent2: (
			bg:				#5a5a5a,
			fg:				mix(#5a5a5a, #fff, 25%),
			fg-bold:		#fff,
			fg-light:		mix(#5a5a5a, #fff, 50%),
			border:			rgba(255,255,255,0.15),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(255,255,255,0.5),
			border2-bg:		rgba(255,255,255,0.2),
			highlight:		accent1
		),

		accent3: (
			bg:				#3c4b51,
			fg:				#ffffff,
			fg-bold:		#ffffff,
			fg-light:		#ffffff,
			border:			#ffffff,
			border-bg:		rgba(255,255,255,0.25),
			border2:		#ffffff,
			border2-bg:		rgba(255,255,255,0.2),
			highlight:		accent1
		)
	);