@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/*
	Faction by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

/* Banner */

	#banner {
		> article {
			&:before {
				display: none;
			}
		}
	}